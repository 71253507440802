import React from 'react';
import * as qs from 'query-string';
import './App.css';

const TILE_URL_PREFIX = 'https://maps.runescape.wiki/osrs/tiles/';//0_2019-10-31_1/1/0_24_25.png'
const BORDER_STYLE = '1px solid rgba(0, 0, 0, 0.5)';

function App() {
  const params = qs.parse(window.location.search);
  const debug = params['debug'] === 'true';
  const chunkMap = new Map();

  let locked = false;

  if (params['chunks']) {
    locked = true;
    const chunks = params['chunks'].split(',');
    parseChunks(chunks);
  }

  function parseChunks(chunks) {
    for (const chunk of chunks) {
      const splits = chunk.split('-');
      chunkMap.set(splits[0], parseInt(splits[1]));
    }
  }

  function createOverlay(element) {
    const overlay = mkDivWithScale(element, 1);
    overlay.className = 'tile-overlay';
    fixBetween(overlay, element);
    overlay.style.zIndex = 9999;
    return overlay;
  }

  function setOverlayColor(overlay) {
    if (chunkMap.has(overlay.id)) {
      const state = chunkMap.get(overlay.id);
      if (state === 1) { // available
        overlay.style.backgroundColor = 'rgba(88, 212, 221, 0.45)';
      } else if (state === 2) { // unlocked
        // overlay.style.backgroundColor = 'rgba(50, 163, 32, 0.4)';
      }
    } else {
      overlay.style.backgroundColor = 'rgba(28, 28, 28, 0.85)';
    }
  }

  function mkDivWithScale(element, scale) {
    if (!element) return;
    const overlay = document.createElement('div');
    overlay.style.width = `calc(${element.style.width} * ${scale})`;
    overlay.style.height = `calc(${element.style.height} * ${scale})`;
    return overlay;
  }

  function fixBetween(element, target) {
    if (!element || !target) return;
    element.style.position = 'fixed';
    element.style.left = target.style.left;
    element.style.top = target.style.top;
    element.style.transform = target.style.transform;
  }

  function centerText(element) {
    element.style.zIndex = 9999999;
    element.style.textAlign = 'center';
    element.style.fontSize = `calc(${element.style.height} / 6)`;
    element.style.lineHeight = `calc(${element.style.height})`;
    element.style.color = 'cyan';
  }

  function mkGrid(size, parent, originTile, scale) {
    const elementSize = `calc(${parent.style.width} * ${1.0 / size})`

    const stdMatrix = translateToStandard(originTile, scale);

    for (let r = 0; r < size; r++) {
      for (let c = 0; c < size; c++) {
        const div = mkDivWithScale(parent, (1.0 / size));
        div.className = 'tile-overlay';
        div.style.position = 'absolute';
        div.style.left = `calc(${c} * ${elementSize})`;
        div.style.bottom = `calc(${r} * ${elementSize})`;
        div.style.border = BORDER_STYLE;
        if (stdMatrix != null) {
          const realMatrix = {
            plane: stdMatrix.plane,
            x: stdMatrix.x + c,
            y: stdMatrix.y + r
          }
          div.id = matrixToKey(realMatrix);
          setOverlayColor(div);
          if (debug) {
            div.innerText = matrixToKey(realMatrix);
            centerText(div);
          }
        }
        parent.appendChild(div);
      }
    }
  }

  function getChunkKey(tile) {
    const key = tile.getAttribute('src');
    return key.substring(key.lastIndexOf('/') + 1).replace('.png', '');
  }

  function getLeafletMap(doc) {
    const leaflets = [...doc.querySelectorAll('img.leaflet-tile')];
    const leafMap = new Map();

    for (const leaflet of leaflets) {
      leafMap.set(getChunkKey(leaflet), leaflet);
    }

    return leafMap;
  }

  function addOutGrid(parent, scale, originTile) {
    switch (scale) {
      case 2: { // 100% scaling
        const div = mkDivWithScale(parent, 1);
        div.id = getChunkKey(originTile);
        setOverlayColor(div);
        div.className = 'tile-overlay';
        div.style.border = BORDER_STYLE;
        if (debug) {
          div.innerText = div.id;
          centerText(div);
        }
        parent.appendChild(div);
        break;
      }
      case 1: { // 50% scaling
        mkGrid(2, parent, originTile, scale);
        break;
      }
      case 0: { // 25% scaling
        mkGrid(4, parent, originTile, scale);
        break;
      }
      case -1: { // 12.5% scaling
        mkGrid(8, parent, originTile, scale);
        break;
      }
      case -2: { // 6.25% scaling
        mkGrid(16, parent, originTile, scale);
        break;
      }
      case -3: { // 3.125% scaling
        mkGrid(32, parent, originTile, scale);
        break;
      }
      default: {
        break;
      }
    }
  }

  function addInGrid(doc) {
    const startX = 32;
    const startY = 127;
    const endX = 123;
    const endY = 76;

    const plane = getMatrix(getChunkKey(doc.querySelector('img.leaflet-tile'))).plane;
    const leafMap = getLeafletMap(doc);

    if (!leafMap) return;

    for (let x = startX; x <= endX; x += 2) {
      for (let y = startY; y >= endY; y -= 2) {
        const tl = `${plane}_${x}_${y}`;
        const tr = `${plane}_${x + 1}_${y}`;
        const bl = `${plane}_${x}_${y - 1}`;
        const br = `${plane}_${x + 1}_${y - 1}`;

        const tlEl = leafMap.get(tl);
        const trEl = leafMap.get(tr);
        const blEl = leafMap.get(bl);
        const brEl = leafMap.get(br);

        if (tlEl && trEl && blEl && brEl) {
          const overlay = document.createElement('div');
          overlay.id = matrixToKey({
            plane: plane,
            x: Math.floor(x / 2),
            y: Math.floor(y / 2)
          })
          overlay.className = 'tile-overlay';
          fixBetween(overlay, tlEl);
          setOverlayColor(overlay);
          overlay.style.zIndex = 9999;
          overlay.style.border = BORDER_STYLE;
          overlay.style.width = `calc(${tlEl.style.width} + ${trEl.style.width})`;
          overlay.style.height = `calc(${tlEl.style.height} + ${blEl.style.height})`;
          if (debug) {
            overlay.innerText = overlay.id;
            centerText(overlay);
          }
          if (tlEl.parentElement !== null) {
            tlEl.parentElement.insertBefore(overlay, tlEl);
          }
        }
      }
    }
  }

  function getMatrix(key) {
    const splits = key.split('_');
    return {
      plane: parseInt(splits[0]),
      x: parseInt(splits[1]),
      y: parseInt(splits[2])
    };
  }

  function matrixToKey(matrix) {
    return `${matrix.plane}_${matrix.x}_${matrix.y}`;
  }

  function translateToStandard(tile, scale) {
    const matrix = getMatrix(getChunkKey(tile));
    switch (scale) {
      case 1: { // 50% -> 100%
        matrix.x *= 2;
        matrix.y *= 2;
        return matrix;
      }
      case 0: { // 25% -> 100%
        matrix.x *= 4;
        matrix.y *= 4;
        return matrix;
      }
      case -1: { // 12.5% -> 100%
        matrix.x *= 8;
        matrix.y *= 8;
        return matrix;
      }
      case -2: { // 6.25% -> 100%
        matrix.x *= 16;
        matrix.y *= 16;
        return matrix;
      }
      case -3: { // 3.125% -> 100%
        matrix.x *= 32;
        matrix.y *= 32;
        return matrix;
      }
      default: {
        break;
      }
    }
  }

  function parseScale(img) {
    const src = img.getAttribute('src');
    return parseInt(src.split(TILE_URL_PREFIX)[1].split('/')[1]);
  }

  function eventInside(event, divRect) {
    return event.clientX >= divRect.left && event.clientX <= divRect.right &&
      event.clientY >= divRect.top && event.clientY <= divRect.bottom;
  }

  function addOverlayEvents(doc, callback) {
    let dragCount = 0;
    let down = false;

    function handleClick(event) {
      if (dragCount === 0 && event.which === 1 && !locked) {
        doc.querySelectorAll('div.tile-overlay[id]').forEach(overlay => {
          let blocked = false;
          doc.querySelectorAll('div.leaflet-control-container > div').forEach(uiElement => {
            if (eventInside(event, uiElement.getBoundingClientRect())) {
              blocked = true;
            }
          });
          if (!blocked) {
            const divRect = overlay.getBoundingClientRect();
            if (eventInside(event, divRect)) {
              if (!chunkMap.has(overlay.id)) {
                chunkMap.set(overlay.id, 1); // 1 == availabile, 2 == unlocked
              } else {
                chunkMap.set(overlay.id, chunkMap.get(overlay.id) + 1);
                if (chunkMap.get(overlay.id) > 2) {
                  chunkMap.delete(overlay.id);
                }
              }
              callback();
            }
          }
        });
      }
      dragCount = 0;
    }

    doc.body.addEventListener('mousedown', function (event) {
      dragCount = 0;
      if (event.which === 1) {
        down = true;
      }
    });
    doc.body.addEventListener('touchstart', function () {
      dragCount = 0;
      down = true;
    });
    doc.body.addEventListener('mousemove', function () {
      if (down) {
        dragCount++;
      }
    });
    doc.body.addEventListener('touchmove', function () {
      if (down) {
        dragCount++;
      }
    });
    doc.body.addEventListener('mouseup', function (event) {
      handleClick(event);
    }, false);
    doc.body.addEventListener('touchend', function (event) {
      handleClick(event);
    }, false);
  }

  function addButton(doc, title, fontIcon, callback) {
    setTimeout(() => {
      const panel = doc.querySelector('div.leaflet-top.leaflet-right');
      const newBtn = document.createElement('div');
      newBtn.classList.add('leaflet-bar', 'leaflet-control');
      const anchor = document.createElement('a');
      anchor.href = '#';
      anchor.role = 'button';
      anchor.title = title;
      anchor['aria-label'] = title;
      const icon = document.createElement('i');
      anchor.onclick = function () {
        if (callback) {
          callback(icon);
        }
      };
      icon.classList.add('fa', fontIcon);
      anchor.appendChild(icon);
      newBtn.appendChild(anchor);
      panel.appendChild(newBtn);
    }, 1000);
  }

  function addExportButton(doc) {
    addButton(doc, 'Export URL', 'fa-share-alt', () => {
      let path = '?chunks=';
      chunkMap.forEach((state, chunk) => {
        if (path.length !== 8) {
          path += ',';
        }
        path += `${chunk}-${state}`;
      });

      window.location = path;
    });
  }

  function addLockButton(doc) {
    const icon = locked ? 'fa-lock' : 'fa-unlock-alt';
    addButton(doc, 'Lock Map', icon, (iconBtn) => {
      locked = !locked;
      iconBtn.classList.remove(...iconBtn.classList);
      iconBtn.classList.add('fa', locked ? 'fa-lock' : 'fa-unlock-alt');
    });
  }

  function removeHelpButton(doc) {
    setTimeout(() => {
      doc.querySelector('div.leaflet-control-help').remove();
    }, 1000);
  }

  function handleIFrameLoad(data) {
    const frame = data.target; // iframe from SyntheticEvent
    const doc = frame.contentWindow.document;

    function getScale() {
      return parseScale(doc.querySelector('img.leaflet-tile'));
    }

    function renderChunks() {
      doc.querySelectorAll('.tile-overlay').forEach(x => {
        x.remove();
      });

      var scale = 2;

      doc.querySelectorAll('img.leaflet-tile').forEach(tile => {
        const prev = tile.previousElementSibling;
        if (prev == null || prev.className !== 'tile-overlay') {
          scale = parseScale(tile);
          if (scale !== 3) {
            const overlay = createOverlay(tile);
            addOutGrid(overlay, scale, tile);
            tile.parentElement.insertBefore(overlay, tile);
          }
        }
      });

      if (scale === 3) {
        addInGrid(doc);
      }
    }

    let dragging = false;
    let lastDrag = -1;
    let lastScale = -1;

    doc.onmousedown = function () {
      dragging = true;
    };

    doc.ontouchstart = function () {
      dragging = true;
    };

    doc.onmousemove = function () {
      if (dragging) {
        lastDrag = Date.now();
      }
    };

    doc.ontouchmove = function () {
      if (dragging) {
        lastDrag = Date.now();
      }
    };

    doc.onmouseup = function () {
      dragging = false;
    };

    doc.ontouchend = function () {
      dragging = false;
    };

    addOverlayEvents(doc, () => {
      renderChunks();
    });

    removeHelpButton(doc);
    addExportButton(doc);
    addLockButton(doc);

    setInterval(() => {
      // Add chunks if they're not found.
      if (!doc.querySelector('.tile-overlay')) {
        renderChunks();
      } else {
        const scale = getScale();
        if (scale !== lastScale) { // re-render chunks when scale changes
          lastScale = scale;
          renderChunks();
        } else if (Date.now() - lastDrag < 3000) { // re-render chunks if dragging
          renderChunks();
        }
      }
    }, 100);
  }

  return (
    <div className="App">
      <header className="App-header">
        <iframe id='os-map'
          title='OSRS Wiki Map'
          src='doogle-maps/dist/index.html'
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            width: '100%',
            height: '100%',
            border: 'none',
            margin: 0,
            padding: 0,
            overflow: 'hidden'
            // zIndex: 999999
          }}
          onLoad={handleIFrameLoad}>
          Your browser doesn't support iframes
        </iframe>
      </header>
    </div>
  );
}

export default App;
